const generalProps = {
  app_version: "1.9.8",
  version_date: "2022/09/16",
  time_part: [
    {
      part_name: "全時間帯",
      start: "2022/09/17 08:00",
      end: "2022/09/18 17:00",
    },
    {
      part_name: "17日(土) 9時30分～12時00分",
      start: "2022/09/17 09:30",
      end: "2022/09/17 12:00",
    },
    {
      part_name: "17日(土) 10時30分～13時00分",
      start: "2022/09/17 10:30",
      end: "2022/09/17 13:00",
    },
    {
      part_name: "17日(土) 11時30分～14時00分",
      start: "2022/09/17 11:30",
      end: "2022/09/17 14:00",
    },
    {
      part_name: "17日(土) 12時30分～15時00分",
      start: "2022/09/17 12:30",
      end: "2022/09/17 15:00",
    },
    {
      part_name: "17日(土) 13時30分～16時00分",
      start: "2022/09/17 13:30",
      end: "2022/09/17 16:00",
    },
    {
      part_name: "18日(日) 9時30分～12時00分",
      start: "2022/09/18 09:30",
      end: "2022/09/18 12:00",
    },
    {
      part_name: "18日(日) 10時30分～13時00分",
      start: "2022/09/18 10:30",
      end: "2022/09/18 13:00",
    },
    {
      part_name: "18日(日) 11時30分～14時00分",
      start: "2022/09/18 11:30",
      end: "2022/09/18 14:00",
    },
    {
      part_name: "18日(日) 12時30分～15時00分",
      start: "2022/09/18 12:30",
      end: "2022/09/18 15:00",
    },
  ],
};

export default generalProps;
